import React, { useState, useContext, useEffect } from "react";

import CrewCard from "../CrewCard";
import Loader from "./ModalX/loader";
import contractAddress from "../../utils/Contract.json";
import UserContext from '../../context/userContext/UserContext';
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";
import Icons from "../../assets/vector/icons";

import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";

export default function ListWaifuTractor({ licenceInput, setLicenseModal, pricePeach, setModalReloadData }) {
    const [listItems, setListItems] = useState(null)
    const [reloadData, setReloadData] = useState(true)
    const [select, setCardSelect] = useState('SELECT')
    const { contract, account, chainId } = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [allowance, setAllowance] = useState(false)

    const [arrayIcon] = useState([cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])

    const [licenceDays, setLicenceDays] = useState(1)

    useEffect(() => {
        if (contract && account && reloadData) {

            setReloadData(false)
            setListItems(null)
            contract.ContractCrew.methods.getFormationIdsOf(account).call().then(result => {
                const getAllCrews = []
                const tempCrews = []
                result.map((element, index) => { if (element !== "1000000000000000000") return getAllCrews.push(contract.ContractCrew.methods.formations(element).call().then(result => tempCrews[index] = { ...result, id: element })) })
                Promise.all(getAllCrews).then(result => setListItems(tempCrews.reverse())).catch(err => setListItems([]))
            })
        }
    }, [contract, account, reloadData]);

    const HaventCrews = () => (
        <div className="grid grid-rows-mint justify-center text-center mt-16">
            <img src={waifuMintIcon} alt="waifu havent mint" className="w-44 mx-auto self-center"></img>
            <span className="font-bold text-base text-black">It seems that you don't have any crews at the moment!</span>
            <span className="text-sm text-black">They will appear here right after you create them.</span>
        </div>
    );

    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])




    const onSubmitLicences = (id, licences) => {
        if (contract) {
            setLoading(true)
            if (!allowance) {
                contract.contractPeach.methods.approve(process.env.GATSBY_DWGAME, 5 + '0'.repeat(24)).send({ from: account }).then(result => {
                    contract.contractDWGameFarmer.methods.addLicenses(id, parseInt(licences)).send({ from: account, value: 5 + '0'.repeat(14) }).then(result => { setLoading(false); setReloadData(true); setModalReloadData(true) }).catch(err => { setLoading(false); });
                }).catch(err => { console.error(err); setLoading(false); })
            }
            else contract.contractDWGameFarmer.methods.addLicenses(id, parseInt(licences)).send({ from: account, value: 5 + '0'.repeat(14) }).then(result => { setLoading(false); setReloadData(true) }).catch(err => { setLoading(false); });
        }
    }

    if (loading) return (<div className="p-10"> <Loader /></div>)

    const prices = {
        7: "7",
        15: "13",
        30: "26",
    }


    const inputFilter = (e) => {

        let a = e.target.value.replace(/[^0-9\.]+/g, '');


        if (parseInt(a) === 0) return setLicenceDays("1")
        setLicenceDays(parseInt(a))

    }



    return <>
        {!listItems ? <div><Loader /></div> : listItems.length ?
            <>
                <div className="w-1/2 mx-auto mb-5 text-left">

                    <label htmlFor="days" className="text-left text-white text-2xl font-bold w-full">Days: </label>
                    <div className="bg-white rounded-full p-1 py-2 pl-3 flex overflow-hidden my-2">
                        <input type="number" id="days" className="w-full text-xl font-semibold outline-none mr-1 pl-5" onInput={inputFilter} value={licenceDays} placeholder="Digit a number of licenses per day" />

                        <div className={`rounded-full w-9 h-9 flex justify-center items-center mr-2 transition-all duration-300 text-darkPink bg-primarypeach`}>
                            <Icons.group />
                        </div>
                    </div>
                </div>

                <div className="flex pt-10 flex-wrap justify-center md:justify-between px-10 w-[80vw] h-[50vh] md:h-[80vh] overflow-y-auto">
                    {listItems?.map(element => <div className="mb-12"><CrewCard icon={arrayIcon} licenceDays={licenceDays} onSubmitLicences={onSubmitLicences} {...element} nameCrew={element.name} harvesting={true} /></div>)}
                </div>
            </> : <HaventCrews />}
    </>
}