import React from "react"
import harvestingLoading from "../../assets/img/harvestLoading.gif"


import harvestingSuccess from "../../assets/vector/harvestingSuccess.svg"
import harvestingFailed from "../../assets/vector/harvestingFailed.svg"

export default function HarvestStep3({ errorHarvestCall, reloadHarvestModal, blockLeft, loading, typeResult, errorHarvest, bgMat, loadingHarverst }) {
    return (
        <>
            {loading ? (
                <div className="flex flex-col text-center items-center w-full">
                    <img
                        src={harvestingLoading}
                        className="mx-auto rounded-md "
                        alt="loading"
                    />
                    {blockLeft > 0 && (
                        <p className="text-2xl font-bold text-white mt-3">
                            You have {blockLeft} blocks left to confirm the transaction
                        </p>
                    )}
                </div>
            ) : (
                <div className="bg-primary p-3 flex justify-between flex-col-reverse lg:flex-row rounded-lg w-full md:w-8/12 mx-auto">
                    <div className="p-2 relative">
                        <h2 className="text-[#AA2E74] text-2xl pb-4 text-left">
                            <strong>
                                {typeResult === "success"
                                    ? loadingHarverst > 5
                                        ? "CONGRATS!"
                                        : "UPS!"
                                    : "FAILED!"}
                            </strong>
                        </h2>
                        <p className="text-md pb-4 text-left font-semibold">
                            <span>
                                {typeResult === "success"
                                    ? "Your Farmer Waifus have finished harvesting"
                                    : "Your Farmer Waifus have not harvested"}
                            </span>
                        </p>
                        {typeResult === "success" && (
                            <p className="text-lg pb-4 text-left">
                                <strong>
                                    Material:
                                    {loadingHarverst !== -1 ? (
                                        <>
                                            <img
                                                src={bgMat}
                                                alt="material"
                                                className="inline w-8 m-auto"
                                            />
                                            {loadingHarverst > 0
                                                ? loadingHarverst
                                                : "Maybe next time..."}
                                        </>
                                    ) : (
                                        "Harvesting... "
                                    )}
                                </strong>
                            </p>
                        )}
                        <p className="text-md mb-5 text-left  font-semibold">

                            <div>

                                Don't forget to check the status of your Farmer Waifus and
                                Tractors
                            </div>
                        </p>
                        {errorHarvest && blockLeft > 0 && (
                            <div
                                className="bg-red-300 px-3 py-3 rounded-lg  mb-12 cursor-pointer"
                                onClick={errorHarvestCall}
                            >
                                <span className="text-red-700 font-bold">
                                    You have {blockLeft} blocks left to approve the transaction,
                                    CLICK HERE!!
                                </span>
                            </div>
                        )}
                        <button
                            onClick={reloadHarvestModal}
                            className="absolute bottom-0 left-0 rounded-lg text-md bg-[#AA2E74] text-white p-2 mb-2 ml-2"
                        >

                            <strong> CLOSE </strong>
                        </button>
                    </div>
                    <div>
                        <img
                            src={
                                typeResult === "success" && loadingHarverst > 0
                                    ? harvestingSuccess
                                    : harvestingFailed
                            }
                            alt="success"
                            className="w-[15rem]  m-auto md:m-auto"
                        />
                    </div>
                </div>
            )}
        </>
    )
}
