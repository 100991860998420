import React, { useState } from 'react'
import Loader from "../../components/elements/ModalX/loader"
import WaifuCard from "../elements/WaifuCardItem"
import { Swiper, SwiperSlide } from "swiper/react"

import CrewCard from '../CrewCard'

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"


import "../../assets/css/swiper.css"



import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";

export default function HarvestStep2({ needWaifu, waifus, bgMat, handleSelectWaifu, selectWaifu, modal }) {

    const [arrayIcon] = useState([cottonIcon, woolIcon, leatherIcon, leaveIcon, flaxIcon, silkIcon])

    return (
        <>
            <div className="flex justify-between items-center md:items-end mb-5 flex-col md:flex-row">
                <div className="rounded-md bg-pink-200 bg-opacity-70 p-2 flex items-center mb-2 md:mb-0">
                    <div className="text-pink-700 mr-2 text-left">
                        WP Required:
                        <span className="font-bold">
                            {needWaifu.num}/{needWaifu.den}
                        </span>
                    </div>
                </div>
            </div>

            <div className="bg-primary p-5 overflow-x-auto overflow-y-hidden flex w-full rounded-lg ">
                {waifus ? (
                    <>
                        {waifus.length > 0 ? (
                            <Swiper
                                breakpoints={{
                                    1250: { slidesPerView: 3 },
                                    900: { slidesPerView: 2 },
                                    600: { slidesPerView: 1 },
                                    200: { slidesPerView: 1 },
                                }}
                                spaceBetween={20}
                                pagination={{ clickable: true }}
                                className="mySwiper"
                            >
                                {waifus.map(element => (
                                    <SwiperSlide>
                                        <CrewCard {...element} nameCrew={element.name} icon={arrayIcon} modal={modal} handleSelectWaifu={handleSelectWaifu} selectWaifu={selectWaifu} selectHarvest={true} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <h3 className="text-[#AA2E74] text-center w-full text-md font-bold ">

                                You have no crews available, create some crew or check the
                                licenses
                            </h3>
                        )}
                    </>
                ) : (
                    <div className="h-80 flex justify-center items-center w-full">
                        <Loader />
                    </div>
                )}
            </div>
        </>
    )
}
