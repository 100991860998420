import React, { useState, useEffect, useContext, useRef } from 'react'
import UserContext from '../context/userContext/UserContext';
import contractAddress from "../utils/Contract.json"
import { useWeb3React } from '@web3-react/core'
export default function useHarvesting({ modal }) {

    const { library } = useWeb3React()

    const [step, setStep] = useState(1)
    const [typeResult, setTypeResult] = useState("failed")
    const [selectFarmer, setSelectFarmer] = useState(null)
    const [allowance, setAllowance] = useState(false)
    const [waifus, setWaifus] = useState(null)
    const [allWaifus, setAllWaifus] = useState([])
    const [tractor, setTractor] = useState(null)
    const [allTractor, setAllTractor] = useState([])
    const [selectStartTractor, setSelectStartTractor] = useState(0)
    const [loading, setLoading] = useState(false)

    const [needWaifu, setNeedWaifu] = useState({ num: 0, den: 0 })
    const [needTractor, setNeedTractor] = useState(0)

    const [selectWaifu, setSelectWaifu] = useState([])
    const [selectTractor, setSelectTractor] = useState([])


    const [loadingHarverst, setLoadingHarvest] = useState(-1)
    const [errorHarvest, setErrorHarvest] = useState(null)

    const [blockLeft, setBlockLeft] = useState("")



    const { contract, account, chainId } = useContext(UserContext)

    useEffect(() => {
        if (!modal) {
            setTypeResult("failed")
            setStep(1)
            selectFarmer(null)
            setTractor([])
            setWaifus([])
        }
    }, [modal])



    useEffect(() => {
        if (contract && account) {

            contract.ContractCrew.methods.getFormationIdsOf(account).call().then(result => {
                const getAllCrews = []
                const tempCrews = []
                result.map(element => { if (element !== "1000000000000000000") return getAllCrews.push(contract.ContractCrew.methods.formations(element).call().then(result => tempCrews.push({ ...result, id: element }))) })
                Promise.all(getAllCrews).then(result => {

                    let tempCrewsFilter = [];

                    tempCrews.map(element => {

                        let timeNow = new Date()
                        let available = element.availability - (timeNow.getTime() / 1000)
                        if (element.licenses > 0 && available <= 0) {
                            tempCrewsFilter.push(element)
                        }

                    })
                    setWaifus(tempCrewsFilter.reverse())
                }).catch(err => setWaifus([]))
            })

        }
    }, [contract, account])


    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])



    const errorHarvestCall = () => {
        setLoading(true)
        contract.contractDWGameHarvest.methods.resolveHarvest(errorHarvest._id, errorHarvest.field, errorHarvest.rarity, account).send({ from: account }).then(result => {
            setTypeResult("success");
            setLoading(false)
            setLoadingHarvest(parseInt(result.events[0].raw.data))
            setErrorHarvest(null)
        }).catch((err) => { setLoading(false); setErrorHarvest(errorHarvest) })

    }

    useEffect(() => {

        let interval;
        if (step === 3) {

            setLoading(true)

            if (contract) {
                let field = (modal - 1) + (selectFarmer - 1)
                let findCrew = waifus.find(element => element.id === selectWaifu)


                setBlockLeft("")
                contract.contractDWGameHarvest.methods.goHarvest(field, parseInt(selectWaifu)).send({ from: account, value: 5 + '0'.repeat(14) }).then(async result => {
                    setErrorHarvest(null)
                    console.log(result)
                    let _id = result?.events["CallbackRequest"].returnValues._id

                    let harvestBlock = result?.blockNumber

                    let active = true

                    let idInterval = setInterval(async () => {

                        let currentBlock = await library.eth.getBlockNumber()
                        let resultCurrentBlock = currentBlock - harvestBlock

                        setBlockLeft(220 - resultCurrentBlock)
                        if (resultCurrentBlock >= 2 && (220 - resultCurrentBlock) > 5 && active) {

                            active = false

                            contract.contractDWGameHarvest.methods.resolveHarvest(_id, field, parseInt(findCrew.rarity), account).send({ from: account }).then(result => {
                                setTypeResult("success");
                                setLoading(false)
                                setLoadingHarvest(parseInt(result.events[0].raw.data))
                                setErrorHarvest(null)
                                /* localStorage.setItem("harvestActive", (new Date()).getTime()) */

                            }).catch((err) => { setLoading(false); setErrorHarvest({ _id, field, rarity: parseInt(findCrew.rarity) }) })
                        }
                        if (resultCurrentBlock > 220) {
                            setBlockLeft(0)

                            clearInterval(idInterval)
                        }

                    }, 2000)

                }).catch(err => { console.error(err); setLoading(false) })
            }
        }

        return () => { clearInterval(interval) }

    }, [step])

    const selectOneFarmCard = (farmerId, wp) => {

        setStep(step + 1);
        setSelectFarmer(farmerId);
        setNeedWaifu({ num: 0, den: wp })
        setSelectWaifu([])
        setSelectTractor([])
        setNeedTractor(0)

    }

    const handleSelectWaifu = (element) => {
        if (element.id === selectWaifu) {
            setNeedWaifu(prevState => ({ ...prevState, num: 0 }))
            return setSelectWaifu(null)
        }
        setNeedWaifu(prevState => ({ ...prevState, num: element.wpCrew[(modal - 1) / 3] }))
        setSelectWaifu(element.id)
    }


    return { blockLeft, errorHarvest, errorHarvestCall, loadingHarverst, selectWaifu, needWaifu, selectOneFarmCard, handleSelectWaifu, step, setStep, waifus, loading, setSelectFarmer, typeResult }
}
