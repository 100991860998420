import * as React from "react";
import Layout from "../components/layouts/Layout";
import backgroundHarvest from "../assets/img/backgroundSections/harvestBackground.png";
import HarvestingComponent from "../components/Harvesting/Harvesting";


// markup
const Harvesting = () => {

  return (
    <Layout background={backgroundHarvest}>
      <HarvestingComponent />
    </Layout>
  )
}

export default Harvesting
