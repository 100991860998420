import React, { useEffect } from 'react'
import FarmCard from "../elements/farmCard"
import HarvestingWizzard from "./HarvestingWizzard"
import useHarvesting from "../../hooks/useHarvesting"

import HarvestStep1 from "./HarvestStep1"
import HarvestStep2 from "./HarvestStep2"
import HarvestStep3 from "./HarvestStep3"

export default function HarvestingModal({
  setModal,
  modal,
  bgFarm,
  bgMat,
  setModalX,
}) {
  const {
    blockLeft, errorHarvest, errorHarvestCall, loadingHarverst, selectWaifu, needWaifu, selectOneFarmCard, handleSelectWaifu, step, setStep, waifus, loading, setSelectFarmer, typeResult
  } = useHarvesting({ modal })
  const nextStep = () => {
    if (step === 2 && needWaifu.num >= needWaifu.den) setStep(step + 1)
  }

  const disableButton = () => {
    if (step === 2 && needWaifu.num < needWaifu.den) return true
    return false
  }

  const reloadHarvestModal = () => {
    setStep(1)
    setModal(false)
    setModalX(false)
  }

  useEffect(() => {

    if ((step === 2 || step === 3), document) {

      let documentTest = document.getElementsByClassName("swiper-wrapper")

      for (var i = 0; i < documentTest.length; i++) {
        documentTest[i].className += " flex";
      }

    }


  }, [step, document])

  return (
    <div className="relative p-2 px-6 rounded-md w-screen md:w-[80vw]">
      <HarvestingWizzard step={step} />

      {step === 1 && <HarvestStep1 selectOneFarmCard={selectOneFarmCard} modal={modal} bgFarm={bgFarm} bgMat={bgMat} />}
      {step === 2 && <HarvestStep2 needWaifu={needWaifu} waifus={waifus} bgMat={bgMat} handleSelectWaifu={handleSelectWaifu} selectWaifu={selectWaifu} modal={modal} />}
      {step === 3 && <HarvestStep3 reloadHarvestModal={reloadHarvestModal} blockLeft={blockLeft} loading={loading} typeResult={typeResult} errorHarvestCall={errorHarvestCall} errorHarvest={errorHarvest} bgMat={bgMat} loadingHarverst={loadingHarverst} />}


      {step < 3 && (
        <section className="mt-3 flex justify-between">
          <div>
            {step >= 2 && (
              <button
                onClick={() => setStep(step - 1)}
                className={`rounded-lg text-md bg-[#AA2E74] text-white px-3 py-1`}
              >
                <strong> Previous Step</strong>
              </button>
            )}
          </div>
          <div>
            {step >= 2 && (
              <button
                onClick={nextStep}
                className={`rounded-lg text-md text-white ${disableButton() ? "bg-[#7e7e7e]" : "bg-[#61BD4F]"} px-3 py-1 `}
              >
                <strong> Next Step </strong>
              </button>
            )}
          </div>
        </section>
      )}

    </div>
  )
}
