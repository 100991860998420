import React, { useState } from "react";
import Button from "./button";

import backgroundHarvest from "../../assets/img/backgroundSections/harvestBackground.png";


export default function FarmCard({ harvestItem, selectOneFarmCard, bgFarm, difficulty, bgMat, wp, harvestingValues, farmId }) {

    return <div className="bg-white rounded-xl w-60 border-2 pb-4 m-3 border-darkPink text-center items-center">
        <span className="font-bold text-xl"> {difficulty} </span>
        <div className="relative m-auto w-4/5 h-36 border border-black">
            <img src={backgroundHarvest} alt="harvest-background" className="absolute m-auto w-full h-full" />
            <img src={bgFarm} alt="farm" className="absolute m-auto self-center" />
        </div>
        <div className=" m-auto mt-4 w-4/5 font-semibold text-xl">
            <div className="flex justify-between"><span className="text-left text-lg">Waifu Power</span><span className="font-bold text-right"> {wp} WP</span></div>
            <div className="flex justify-between"><span className="text-left text-lg">Win rate</span><span className="font-bold text-right"> {!parseInt(((harvestItem.winRate * 100)+"").split(".")[1]) ? (harvestItem.winRate * 100).toFixed(0) : (harvestItem.winRate * 100).toFixed(1)} %</span></div>
            <div className="flex justify-between"><span className="text-left text-lg">Material</span><div className="inline justify-self-end"><span className="font-bold text-right">{harvestItem.reward}</span><img src={bgMat} alt="material" className="inline w-8 m-auto" /></div></div>
        </div>

        <div className="inline-block mt-2" onClick={() => { selectOneFarmCard(farmId, wp) }}><button><span className="rounded-lg text-white py-1 px-4 bg-[#D765A5] text-lg font-bold">Select</span></button></div>
    </div>
}