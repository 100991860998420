import React from 'react'
import check from "../../assets/vector/check.svg"

export default function HarvestingWizzard({ step }) {
    return (
        <div className="w-full flex justify-center mb-4 md:mb-14">
            <div className="flex flex-row justify-center items-center w-full md:w-9/12">
                <div className="w-16 flex justify-center relative">
                    <div className={`border-4 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold ${step >= 1 ? 'bg-darkPink border-white text-white' : 'bg-white border-gray-400 text-gray-400'}`}>{step > 1 ? <img src={check} alt="check" /> : "1"}</div>
                    <div className="absolute top-full left-1/2 -translate-x-1/2 text-white whitespace-nowrap hidden md:block">Choose Difficulty</div>
                </div>

                <div className={`flex-1 h-2 rounded-full ${step > 1 ? 'bg-darkPink' : 'bg-white'}`}></div>

                <div className="w-16 flex justify-center relative">
                    <div className={`border-4 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold ${step >= 2 ? 'bg-darkPink border-white text-white' : 'bg-white border-gray-400 text-gray-400'}`}>{step > 2 ? <img src={check} alt="check" /> : "2"}</div>
                    <div className="absolute top-full left-1/2 -translate-x-1/2 text-white whitespace-nowrap hidden md:block">Select Crew</div>
                </div>

                {/*  <div className={`flex-1 h-2 rounded-full ${step > 2 ? 'bg-darkPink' : 'bg-white'}`}></div>

                <div className="w-16 flex justify-center relative">
                    <div className={`border-4 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold ${step >= 3 ? 'bg-darkPink border-white text-white' : 'bg-white border-gray-400 text-gray-400' }`}>{step > 3 ? <img src={check} alt="check" /> : "3"}</div>
                    <div className="absolute top-full left-1/2 -translate-x-1/2 text-white whitespace-nowrap hidden md:block">Select Capacity</div>
                </div> */}

                <div className={`flex-1 h-2 rounded-full ${step > 2 ? 'bg-darkPink' : 'bg-white'}`}></div>

                <div className="w-16 flex justify-center relative">
                    <div className={`border-4 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold ${step === 3 ? 'bg-darkPink border-white text-white' : 'bg-white border-gray-400 text-gray-400'}`}>3</div>
                    <div className="absolute top-full left-1/2 -translate-x-1/2 text-white whitespace-nowrap hidden md:block">Go Harvesting</div>
                </div>
            </div>
        </div>
    )
}
