import React, { useState, useContext, useEffect } from 'react'
import Modal from '../elements/Modal';
import ModalX from '../elements/ModalX';
import HarvestingModal from './HarvestingModal'
import ListMiniCards from '../elements/miniWaifus';
import UserContext from "../../context/userContext/UserContext";

import cottonFarm from "../../assets/img/harvestingSection/cottonFarm1.png";
import woolFarm from "../../assets/img/harvestingSection/woolFarm1.png";
import leatherFarm from "../../assets/img/harvestingSection/leatherFarm1.png";
import pineappleFarm from "../../assets/img/harvestingSection/pineappleFarm1.png";
import linenFarm from "../../assets/img/harvestingSection/linenFarm1.png";
import silkFarm from "../../assets/img/harvestingSection/silkFarm1.png";
import HarvestFarm from '../elements/harvestFarm'

import cottonIcon from "../../assets/img/claimIcons/cottonIcon.svg";
import woolIcon from "../../assets/img/claimIcons/woolIcon.svg";
import leatherIcon from "../../assets/img/claimIcons/leatherIcon.svg";
import leaveIcon from "../../assets/img/claimIcons/leaveIcon.svg";
import flaxIcon from "../../assets/img/claimIcons/flaxIcon.svg";
import silkIcon from "../../assets/img/claimIcons/silkIcon.svg";

export default function Harvesting() {

    const [bgFarm, setBgFarm] = useState(cottonFarm);
    const [bgMat, setBgMat] = useState(cottonIcon);
    const [modal, setModal] = useState(false);
    const [modalx, setModalX] = useState(false);
    const { contract, account } = useContext(UserContext);
    const [farmTime, setFarmTime] = useState(null)


    useEffect(() => {
        let timeNow = new Date()
        let timeTwo = new Date(parseInt(localStorage.getItem("harvestActive")) + 86400000)
        let secondsLeft = (timeTwo.getTime() / 1000) - (timeNow.getTime() / 1000);

        let intervalId


        if (secondsLeft > 0) {
            intervalId = setInterval(() => {
                if (secondsLeft > 0) {
                    secondsLeft = secondsLeft - 1
                    setFarmTime({
                        seconds: new Date((secondsLeft || 0) * 1000)
                            .toISOString()
                            .substr(
                                (secondsLeft || 0) < 3600 ? 14 : 11,
                                (secondsLeft || 0) < 3600 ? 5 : 8
                            ),
                    })
                } else {
                    setFarmTime(null)
                }
            }, 1000)
            setFarmTime({
                seconds: new Date((secondsLeft || 0) * 1000)
                    .toISOString()
                    .substr(
                        (secondsLeft || 0) < 3600 ? 14 : 11,
                        (secondsLeft || 0) < 3600 ? 5 : 8
                    ),
            })
        } else setFarmTime(null)

        return () => clearInterval(intervalId)
    }, [])

    return (
        <>
            <ModalX id="harvestModal" view={modalx} action={setModalX} bg={true} btn_close={true}><HarvestingModal modal={modal} setModal={setModal} setModalX={setModalX} bgFarm={bgFarm} bgMat={bgMat} /></ModalX>
            <div className="p-4 lg:px-7">

                <h3 className="bg-[#a2a2a2] border-4 border-[#505050] text-[#fff] text-2xl mt-10 p-3 w-[500px] shadow-2xl mx-auto rounded-md text-center font-bold">HARVEST</h3>

                <section >

                    <div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 mt-20">
                        <HarvestFarm farmTime={farmTime} farm="Cotton" index={1} farmImg={cottonFarm} matImg={cottonIcon} setModal={setModal} setBgFarm={setBgFarm} setBgMat={setBgMat} setModalX={setModalX} />
                        <HarvestFarm farmTime={farmTime} farm="Wool" index={4} farmImg={woolFarm} matImg={woolIcon} setModal={setModal} setBgFarm={setBgFarm} setBgMat={setBgMat} setModalX={setModalX} />
                        <HarvestFarm farmTime={farmTime} farm="Leather" index={7} farmImg={leatherFarm} matImg={leatherIcon} setModal={setModal} setBgFarm={setBgFarm} setBgMat={setBgMat} setModalX={setModalX} />

                        <HarvestFarm farmTime={farmTime} farm="Pineapple" index={10} farmImg={pineappleFarm} matImg={leaveIcon} setModal={setModal} setBgFarm={setBgFarm} setBgMat={setBgMat} setModalX={setModalX} />
                        <HarvestFarm farmTime={farmTime} farm="Linen" index={13} farmImg={linenFarm} matImg={flaxIcon} setModal={setModal} setBgFarm={setBgFarm} setBgMat={setBgMat} setModalX={setModalX} />
                        <HarvestFarm farmTime={farmTime} farm="Silk" index={16} farmImg={silkFarm} matImg={silkIcon} setModal={setModal} setBgFarm={setBgFarm} setBgMat={setBgMat} setModalX={setModalX} />
                    </div>

                </section>

            </div>
        </>
    )
}
