import React from 'react'
import FarmCard from '../elements/farmCard'
import harvestingValues from "../../utils/Harvesting.json"
export default function HarvestStep1({ selectOneFarmCard, modal, bgFarm, bgMat }) {
    return (
        <div className="flex flex-row flex-wrap justify-center  lg:justify-around ">
            <FarmCard
                selectOneFarmCard={selectOneFarmCard}
                farmId={1}
                harvestItem={harvestingValues[modal - 1 + 0]}
                wp={(modal - 1 + 1) * 100}
                bgFarm={bgFarm}
                difficulty="Easy"
                bgMat={bgMat}
            />
            <FarmCard
                selectOneFarmCard={selectOneFarmCard}
                farmId={2}
                harvestItem={harvestingValues[modal - 1 + 1]}
                wp={(modal - 1 + 2) * 100}
                bgFarm={bgFarm}
                difficulty="Medium"
                bgMat={bgMat}
            />
            <FarmCard
                selectOneFarmCard={selectOneFarmCard}
                farmId={3}
                harvestItem={harvestingValues[modal - 1 + 2]}
                wp={(modal - 1 + 3) * 100}
                bgFarm={bgFarm}
                difficulty="Hard"
                bgMat={bgMat}
            />
        </div>
    )
}
